<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>
  <div class="row">
    <h1>{{ t['create_dqc_report'] || 'create_dqc_report' }}</h1>
  </div>
  <div class="row">
    <subtitle style="font-style: italic; margin-top: -10px">
      {{ t['create_dqc_report_subtitle'] || 'create_dqc_report_subtitle' }}
    </subtitle>
  </div>
  <div class="expand block" key="form" style="height: 100vh; overflow: hidden">
    <div class="expand container-side" style="height: 100%">
      <div class="left-side" style="overflow-y: auto; height: 100%">
        <div style="padding: 4px">
          <label>{{ t['dqc_report_name'] || 'dqc_report_name' }}</label>
          <input
            v-model="reportName"
            maxlength="50"
            placeholder="Enter report Name"
            type="text"
            style="min-width: 100%"
          />
        </div>
        <div style="padding: 4px; margin-top: 8px">
          <label>{{ t['data_report'] || 'data_report' }}</label>
          <div class="report-container">
            <select v-model="selectedDataReport" style="min-width: 100%">
              <option v-for="report in availableDataReports" :value="report.name" :key="report.id">
                {{ report.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="container-scrolled"
          style="padding: 4px; margin-top: 8px; overflow-y: auto; height: calc(100% - 120px)"
        >
          <label>{{ t['dqc_rules'] }}</label>
          <div v-for="selectedRule in selectedRules" :key="selectedRule.id" style="margin-bottom: 8px; width: 100%">
            <div class="row" style="margin-bottom: 8px; display: flex; width: 100%">
              <div class="ruleSelection" style="flex: 1">
                <select v-model="selectedRule.ruleId" style="width: 100%">
                  <option :disabled="true" :value="null">
                    {{ t['rule'] }}
                  </option>
                  <option v-for="rule in rules" :value="rule.id" :key="rule.id">
                    {{ rule.name }}
                  </option>
                </select>
              </div>
              <div class="selector" style="flex: 3">
                <select v-model="selectedRule.selector" style="width: 100%">
                  <option :disabled="true" :value="''">
                    {{ t['selector'] }}
                  </option>
                  <option
                    v-if="selectedRule.selector && !selectorKeys.includes(selectedRule.selector)"
                    :value="selectedRule.selector"
                  >
                    {{ selectedRule.selector }}
                  </option>
                  <option v-for="selector in selectorKeys" :value="selector" :key="selector">
                    {{ selector }}
                  </option>
                </select>
              </div>
              <div class="tag" style="flex: 1">
                <input
                  v-model="selectedRule.tag"
                  maxlength="50"
                  :placeholder="t['tag']"
                  type="text"
                  style="width: 100%"
                />
              </div>
              <button
                class="ghost"
                :tt="$root.t.confirmation_btn_delete"
                style="margin-right: 0 !important"
                @click="deleteRule(selectedRule)"
              >
                <ui-asset :width="16" :height="16" name="icon_trash" color="var(--colors-text)" />
              </button>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 4px; margin-bottom: 24px">
          <button class="primary hover:underline" @click="addRule">
            {{ t['add_rule'] || 'add_rule' }}
          </button>
        </div>
      </div>
      <div class="right-side" style="overflow-y: auto; height: 100%">
        <div style="padding: 4px">
          <div
            class="dr-variables-input"
            style="display: flex; flex-wrap: wrap; column-gap: 16px; overflow-y: auto; height: calc(100% - 120px)"
          >
            <dr-variable-input
              v-for="param in variableSettingInputs"
              class="dr-variable-input"
              :variableDefinition="param"
              :inputData="inputData?.[param.name]"
              :missingDependencies="missingDependencies[param.name]"
              :value="dataReportVariables[param.name]"
              :key="param.name"
              @update:value="value => updateVariable(param, value)"
              style="flex: 0 0 calc(50% - 8px)"
            />
          </div>
          <button
            v-if="selectedDataReport"
            class="primary"
            style="margin-top: 8px"
            @click="runDr(availableDataReports.find(report => report.name === selectedDataReport))"
          >
            {{ isRunning ? '' : t['run'] }}
            <loading-icon v-if="isRunning" color="white" size="24" />
          </button>
        </div>
        <div style="padding: 4px">
          <div class="row result expand">
            <div class="column expand" v-if="runResult">
              <div>{{ t['result'] || 'result' }}</div>
              <code-editor :code="runResult" language="json" @update="handleChangeResult" max-height="44vh" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding: 4px">
      <div class="action">
        <button class="primary" @click="saveReport">
          {{ t['save'] || 'save' }}
        </button>
        <button class="secondary" @click="cancel">
          {{ t['cancel'] || 'cancel' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import dataQualityReportService from '../../../services/DataQualityReportService'
import { useDrVariables } from '@100-m/hauru/src/components/form/drVariables/lib'

const router = useRouter()
const { rules } = useDataQualityRule()

// ⚠️ Store this variable as hauru overrides route and removes id param
const dataQualityReportId = $root.$route.query.id

const {
  variables: dataReportVariables,
  updateVariable,
  partials,
  inputData,
  missingDependencies,
  initDrVariables,
  runParameters,
} = useDrVariables()

const isLoading = ref(true)
const isRunning = ref(false)

const hasUnsavedChanges = ref(false)
const isNewReport = ref(false)
const reportId = ref(0)
const reportName = ref('')
const selectedRules = ref([])
const selectedDataReport = ref('')
const availableDataReports = ref([])
const dataReportResults = reactive({})
const selectorKeys = ref([])
const runResult = ref(null)

const variableSettingInputs = computed(() => partials.value.concat(runParameters.value))

const extractKeys = (data, parentKey = '') => {
  let keys = []
  if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key
        keys.push(fullKey)
        keys = keys.concat(extractKeys(data[key], fullKey))
      }
    }
  } else if (Array.isArray(data) && parentKey) {
    keys.push(parentKey)
  }
  return keys
}

const initialize = async () => {
  isNewReport.value = !dataQualityReportId
  isLoading.value = true
  reportId.value = dataQualityReportId

  availableDataReports.value = (await dataReportService.getAll()).filter(dr => typeof dr.id === 'number')

  if (dataQualityReportId) {
    const dqReport = await dataQualityReportService.get(dataQualityReportId)
    reportName.value = dqReport.name
    selectedRules.value = dqReport.rules?.map(e => ({ selector: e.path, ruleId: e.rule?.id, tag: e.tag }))
    selectedDataReport.value = dqReport.dataReport?.name

    if (!dqReport.dataReport) {
      return
    }

    const settingVariableParameters = availableDataReports.value.find(
      dr => dr.id === dqReport.dataReport.id,
    )?.settingVariableParameters

    await initDrVariables(settingVariableParameters || [], dqReport.dataReport.variables)
    await initializeSelectorKeys(dqReport.dataReport.id)
  } else {
    resetForm()
  }

  hasUnsavedChanges.value = false
  isLoading.value = false
}

onMounted(initialize)

const resetForm = () => {
  reportName.value = ''
  selectedRules.value = []
  selectedDataReport.value = ''
}

const saveReport = async () => {
  if (!reportName.value || !validateRules(selectedRules.value) || !selectedDataReport.value) {
    $root.toast({ description: $root.t.dqc_error_name, type: 'error', timeout: 5000 })
    return
  }
  try {
    if (isNewReport.value) {
      await dataQualityReportService.create(
        reportName.value,
        selectedRules.value.map(e => ({ path: e.selector, ruleId: e.ruleId, tag: e.tag })).filter(e => e.ruleId),
        selectedDataReport.value,
      )
    } else {
      await dataQualityReportService.update(
        reportId.value,
        reportName.value,
        selectedRules.value.map(e => ({ path: e.selector, ruleId: e.ruleId, tag: e.tag })),
        selectedDataReport.value,
      )
      $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
    }
    router.push($root.appath + 'data-quality-reports')
  } catch (e) {
    $root.toast({ description: e.message, type: 'error', timeout: 5000 })
  } finally {
    hasUnsavedChanges.value = false
  }
}

const addRule = () => {
  selectedRules.value.push({
    id: selectedRules.value.length + 1,
    ruleId: null,
    selector: '',
    tag: '',
  })
}

const deleteRule = rule => {
  const index = selectedRules.value.findIndex(r => r.id === rule.id)
  selectedRules.value.splice(index, 1)
}

const cancel = () => {
  router.push($root.appath + 'data-quality-reports')
}

const initializeSelectorKeys = async dataReportId => {
  isLoading.value = true

  dataReportResults.value = await dataReportService.run(dataReportId, dataReportVariables, {
    preProcess: false,
    postProcess: true,
  })

  refreshSelectorKeys(dataReportResults.value?.data)

  isLoading.value = false
}

const refreshSelectorKeys = dataReportResultData => {
  selectorKeys.value = Array.from(new Set([...extractKeys(dataReportResultData)]))
}

// Ajout du watch pour selectedDataReport
let isUserReverting = false

watch(selectedDataReport, async (newDataReport, oldDataReport) => {
  if (isUserReverting) {
    isUserReverting = false
    return
  }

  if (newDataReport !== oldDataReport) {
    if (selectedRules.value.length > 0 && oldDataReport) {
      if (window.confirm($root.t.erase_rules)) {
        selectedRules.value = []
      } else {
        isUserReverting = true
        selectedDataReport.value = oldDataReport
        return // Exit early if the user cancels the confirmation
      }
    }

    if (newDataReport) {
      const selectedReport = availableDataReports.value.find(report => report.name === newDataReport)

      if (selectedReport) {
        await initDrVariables(selectedReport.settingVariableParameters, selectedReport.variables)
        await initializeSelectorKeys(selectedReport.id)
      }
    } else {
      // Reset keys and results if newDataReport is null or empty
      selectorKeys.value = []
      dataReportResults.value = {}
    }
  }
})

const validateRules = rules => {
  return rules.every(rule => rule.ruleId && rule.selector)
}

const runDr = async report => {
  isRunning.value = true
  runResult.value = null

  const r = await dataReportService.run(report.id, dataReportVariables, { forceCalculate: false })

  const resultRaw = r?.data?.result || r?.error || {}

  refreshSelectorKeys(r?.data || [])

  runResult.value = JSON.stringify(resultRaw, null, 2)
  isRunning.value = false
}
</script>

<style scoped>
button {
  margin-right: 8px;
}

.ruleSelection,
.selector,
.tag {
  margin-right: 8px;
}

.action {
  margin-right: 5px;
}

.container-scrolled {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 42vh;
}

.ruleSelection {
  width: 20%;
}
.selector {
  width: 60%;
}

.tag {
  width: 20%;
}

.report-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.container-side {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.left-side {
  width: 50%;
  margin-right: 24px;
}

.right-side {
  width: 50%;
}

.dr-variable-input {
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .dr-variable-input {
    flex: 0 0 100%;
  }
}
</style>
