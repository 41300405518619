<script setup lang="ts">
// @ts-nocheck
import { computed, ref, watch } from 'vue'
import { IGridState, useGridAPI } from '@hauru/common'
const props = defineProps<{
  data: { id: string; type: string; name: string; EMV: number }[]
  options: { state: IGridState }
}>()
const tdata = computed(() => {
  const names = {}
  const data = flat(props.options.state.metadata.initialMetadata.tree).map(path => {
    const id = path.map(v => v.row_id).join('.')
    const lastId = path.at(-1).row_id
    const name = path.at(-1).name
    const row = props.options.state.dataMap.map[lastId]
    const x = { ...row, id }
    Object.defineProperty(x, '_name', { value: name, enumerable: false })
    names[lastId] = x
    return x
  })
  Object.defineProperty(data, '_names', { value: names, enumerable: false })
  return data
})
function flat(node, path = [], acc = []) {
  if (path.length) acc.push(path)
  node.nodes?.forEach(child => flat(child, path.concat(child), acc))
  return acc
}
const path = computed(() => ($root.$route.query.path || '') as string)
const hidden = ref({} as Record<number, boolean>)
const bindings = ref<{ state: IGridState; options: any }[]>([])
watch(
  () => [path.value, props.data, props.options.state.find],
  () => {
    if (props.options.state.find) {
      const regex = new RegExp(props.options.state.find, 'ig')
      const filteredData = tdata.value
        .filter(v => Object.values(v).some(v => regex.test(v)))
        .map(v => ({ ...v, Path: v.id, '>': tdata.value.some(x => x.id.startsWith(v.id + '.')) ? '>' : '' }))
      const type = 'SEARCH'
      const level = 0
      const options = {
        actions: [],
        showCheckbox: false,
        columnMinWidth: 12,
        freezedColumnsCount: 0,
        autoexpandColumns: false,
        autosizeColumn: () => null,
        type,
        level,
      }
      const state = useGridAPI(options)
      state.setGrid({ data: filteredData, metadata: {} })
      const prev = props.options.state
      const next = state
      if (state.columns.headers.list.length) {
        next.columns.restoreColumns(prev.columns.headers.list)
        state.columns.headers.list.at(-2).setWidth(400)
        state.columns.headers.move(state.columns.headers.list.length - 2, 0)
        state.columns.headers.list.at(-1).setWidth(12)
        state.columns.headers.list.at(-1).setAutoSized(true)
      }
      watch(
        () => state.selection.items.list,
        () => {
          setTimeout(() => {
            props.options.state.setFind('')
            const selection = state.selection.items.list.map(v => state.dataMap.map[v]).filter(v => v)
            const last = selection.at(-1) as any
            if (!last) return $root.$router.push({ query: { path: path.value.split('.').slice(0, level).join('.') } })
            if (path.value === last.id) return
            $root.$router.push({ query: { path: last.id } })
          })
        },
        { deep: true },
      )
      bindings.value = [{ state, options }]
      return
    }
    const parts = path.value.split('.')
    const filteredData = tdata.value.filter(v =>
      v.id
        .split('.')
        .slice(0, -1)
        .every((part: string, index: number) => hidden.value[index] || part === parts[index]),
    )
    const columns = Object.values(filteredData.group(v => v.id.split('.').length))
    bindings.value = columns.map((column, level) => {
      const type = column[0]?._name
      // HACK: SLOW, inefficient
      const data = column.map(v => ({ ...v, '>': tdata.value.some(x => x.id.startsWith(v.id + '.')) ? '>' : '' }))
      const options = {
        actions: [],
        showCheckbox: false,
        columnMinWidth: 12,
        freezedColumnsCount: 0,
        autoexpandColumns: false,
        autosizeColumn: () => null,
        type,
        level,
      }
      const state = useGridAPI(options)
      state.setGrid({ data, metadata: {} })
      const prev = props.options.state
      const next = state
      next.columns.restoreColumns(prev.columns.headers.list)
      state.columns.headers.list.at(-1).setWidth(12)
      state.columns.headers.list.at(-1).setAutoSized(true)
      //
      const parts = path.value?.split('.')
      if (parts?.length >= level) {
        state.selection.items.deselectAll()
        state.selection.items.toggleSelect(parts.slice(0, level + 1).join('.') as any, true)
      }
      watch(
        () => state.selection.items.list,
        () => {
          setTimeout(() => {
            if (!state.ref?.contains(document.activeElement)) return
            const selection = state.selection.items.list.map(v => state.dataMap.map[v]).filter(v => v)
            const last = selection.at(-1) as any
            if (!last) return $root.$router.push({ query: { path: path.value.split('.').slice(0, level).join('.') } })
            if (path.value === last.id) return
            $root.$router.push({ query: { path: last.id } })
          })
        },
        { deep: true },
      )
      setTimeout(() => µ('.overflow-auto.gap-4')?.scroll(10000, 0), 100)
      return { state, options }
    })
  },
  { immediate: true },
)
</script>

<template>
  <div class="[&_[nx-header-container]_.group\/resizer]:hidden h-full">
    <div class="flex gap-4 overflow-auto h-full">
      <template v-for="binding in bindings" :key="binding.options.level">
        <div class="i-carbon/chevron-right -mx-2 -mt-px h-7 shrink-0 text-gray-700" v-if="binding.options.level"></div>
        <div
          class="i-carbon/right-panel-close -mx-2 -mt-px h-7 shrink-0 cursor-pointer"
          :tooltip="`OPEN ${binding.options.type}`"
          @click="hidden[binding.options.level] = false"
          v-if="hidden[binding.options.level]"
        ></div>
        <div v-else>
          <div
            class="border-b-primary group flex h-7 cursor-pointer select-none items-center border-b-2"
            @click="$router.push({ query: { path: path.split('.').slice(0, binding.options.level).join('.') } })"
          >
            <div class="m-auto truncate text-md font-semibold">{{ binding.options.type }}</div>
            <div
              class="i-carbon/right-panel-open invisible group-hover:visible"
              :class="binding.options.level === 0 && 'pointer-events-none'"
              :tooltip="`CLOSE ${binding.options.type}`"
              @click.stop="hidden[binding.options.level] = true"
              v-if="binding.options.level"
            ></div>
          </div>
          <nx-grid
            class="overflow-auto max-h-[calc(100%-1.75rem)]"
            :state="binding.state"
          >
            <template v-for="name in Object.keys($slots)" #[name]="scope">
              <slot :name="name" v-bind="scope" />
            </template>
            <template #header="{ header }">
              <div v-if="header.label === '>'"></div>
            </template>
            <template #cell="{ cell, row, header, view }">
              <div nx-cell class="truncate px-2 tabular-nums" v-if="header.label === 'Path'">
                <div class="flex gap-1 truncate text-xs">
                  <div
                    class="flex gap-1"
                    :class="i === 0 || i === cell.split('.').length - 2 ? '' : 'min-w-[12px] truncate'"
                    v-for="(id, i) in cell.split('.')"
                  >
                    <template v-if="cell.split('.').length !== i + 1">
                      <div class="h-2 w-2 font-bold">{{ i + 1 }}</div>
                      <div class="truncate">{{ tdata._names[id]._name }}</div>
                      <div
                        class="i-carbon/chevron-right my-auto ml-auto shrink-0"
                        v-if="cell.split('.').length !== i + 2"
                      ></div>
                    </template>
                  </div>
                </div>
              </div>
              <div
                nx-cell
                class="my-auto ml-auto"
                :class="cell && 'i-carbon/chevron-right'"
                v-if="header.label === '>'"
              ></div>
            </template>
          </nx-grid>
        </div>
      </template>
    </div>
  </div>
</template>

<!-- HACK: not sure why these 2 icon do not load in hauru. -->
<!-- prettier-ignore -->
<style>
.i-carbon\/right-panel-open {
  -webkit-mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v9H9.83l3.58-3.59L12%2010l-6%206l6%206l1.41-1.41L9.83%2017H20v9H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
  mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v9H9.83l3.58-3.59L12%2010l-6%206l6%206l1.41-1.41L9.83%2017H20v9H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
}
.i-carbon\/right-panel-close {
  -webkit-mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v20H4v-9h10.17l-3.58%203.59L12%2022l6-6l-6-6l-1.41%201.41L14.17%2015H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
  mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v20H4v-9h10.17l-3.58%203.59L12%2022l6-6l-6-6l-1.41%201.41L14.17%2015H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
}
.i-carbon\/right-panel-close, .i-carbon\/right-panel-open {
  background: currentColor;
  display: inline-block;
  height: 1em;
  width: 1em;
}
</style>
