<script setup>
import { ref, onMounted, onUnmounted, computed, reactive, watch } from 'vue'
import { useDetails } from '../../composables/useDetails.js'
import { useAutocomplete } from '../../composables/useAutocomplete.js'
import { useXfilter } from '../../composables/useXfilter.js'
import { useDates } from '../../composables/useDates'
import { useShare } from '../../composables/useShare'
import Error from '@100-m/hauru/src/components/ui/error.vue'

const { initAutocomplete, autocompleteAvailableData, activeAutocompleteFilters, onFiltersUpdateAutoComplete } =
  useAutocomplete()
const { initXfilter, filteredDataset, datasetByDimensions } = useXfilter()
const { getTimeSeries, getDetails } = useDetails()
const { getShare } = useShare()
const { getAllocationDates } = useDates()

//refs definition
const loaded = ref(false)
const error = ref(null)
const availableDimensions = ref()
const extension = ref('csv')
const shareCharacteristics = ref({})
const params = reactive({})
const assetData = ref()
const tooltip = ref()
const plot_weight = ref(null)
const plot_contribution = ref(null)
const plot_pnl = ref(null)
const plot_price = ref(null)
const plot_quantity = ref(null)

//methods definition
const leave = (el, done) => {
  el.style.opacity = 0
  setTimeout(() => done(), 500)
}

const loadData = async () => {
  loaded.value = false
  error.value = null
  try {
    params.isinShare = $root.$route.fullPath.split('/')[2].split('-')[1]
    params.domain = $root.$route.query.domain || $root.domain.join('|')
    params.asof = $root.domain[1]
    params.lang = $root.$route.query.lang || $root.lang
    params.externalOptions = { valuationStyle: $root.valuationStyle }
    const share = await getShare(params)
    params.fundId = share.fundId
    params.benchmarkId = share.benchmarkId
    if (!$root.dates || $root.dates.isin_share !== params.isinShare || $root.dates.query !== 'allocation') {
      $root.dates = await getAllocationDates(params)
      $root.dates.isin_share = params.isinShare
      $root.dates.query = 'allocation'
      $root.refresh = $root.refresh + 1
    }
    params.domain = $root.domain.join('|')
    params.asof = $root.domain[1]
    params.date = $root.domain[1]
    params.compareDate = $root.domain[0]
    params.dimensions = share.axisOfAnalysis

    const allocation = await getDetails(params)
    $root.query.fuid && getAssetData($root.query.fuid)
    shareCharacteristics.value = share.characteristics || {}
    $root.tab_benchmark = { id: share.benchmarkName, label: 'benchmark' } // this.data.share.benchmark
    $root.tab_userflow_name = $root.$route.fullPath.split('/')?.[2]?.split('-')?.[0] // this.data.share.slug
    $root.nav_data.path = share.characteristics?.share_letter
    $root.refresh = $root.refresh + 1
    params.asof = share.asof
    availableDimensions.value = share.axisOfAnalysis
    initAutocomplete(allocation, ['name', ...availableDimensions.value])
    initXfilter(allocation, ['name', ...availableDimensions.value])
    $root.bench_weight_nul = filteredDataset.value.map(d => d.weight_benchmark)?.sum() === 0
    // if (filteredDataset.value.map(d => d.weight_benchmark).sum() === 0) {
    //   document.querySelector('main').classList.add('no_benchmark_data')
    // }
  } catch (e) {
    error.value = e
    console.error(e)
  } finally {
    loaded.value = true
  }
}

const getAssetData = async fuid => {
  assetData.value = null

  if (fuid) {
    params.fuid = fuid
    params.externalOptions = { valuationStyle: $root.valuationStyle }

    assetData.value = await getTimeSeries(params)
  }
}

const formatArrayForPlotline = (arrayToFormat, form) => {
  const formattedArray = arrayToFormat.reduce((acc, a) => {
    //form is an array defining the plots we want to draw on the graph
    //example of form : [{plot:'fund', field:'weight_fund'}, {plot:'benchmark', field:'weight_benchmark'}]
    //this will display 2 plots on the same graph, one for fund and one for bench
    //could be used for other screens
    let formattedObj = {}
    form.forEach(item => {
      formattedObj[item.plot] = a[item.field]
    })
    acc[a.date] = formattedObj
    return acc
  }, {})
  return formattedArray
}

const exportTable = (additionnalsCols = [], extension = 'csv') => {
  const tableToExport = detailsDataset.value.map(doc => {
    const lineToExport = {}
    columns.value.concat(additionnalsCols).map(column => {
      if (column === 'pnl') lineToExport[column] = doc['pnlOrFeesPerFund_' + metric.value] || 'NA'
      else lineToExport[column] = doc[column] || doc[column + '_' + metric.value] || doc.axis[column] || 'NA'
    })
    return lineToExport
  })

  switch (extension) {
    case 'csv':
      return tableToExport.dlCSV($root.lang, 'lines.csv')
    case 'xlsx':
      return tableToExport.dlXLS('lines.xlsx')
  }
}

const changeExt = e => {
  extension.value = e.target.value
}

//life cycle methods definition
onMounted(loadData)
onUnmounted(() => ($root.bench_weight_nul = false))

//watchers definition
watch(() => $root.query.domain, loadData)
watch(() => $root.query.fuid, getAssetData)
watch(() => $root.valuationStyle, loadData)
watch(
  () => tooltip.value,
  newValue => {
    ;[plot_weight, plot_price, plot_contribution, plot_quantity, plot_pnl].map(plot => {
      plot.value?.$refs.component.set('guideline', newValue)
      plot.value?.$refs.component.components.xGuideline.value(new Date(newValue))
    })
  },
)

//computed definition
const metric = computed(() =>
  $root.query.metric?.includes('benchmark') ? 'benchmark' : $root.query.metric ? $root.query.metric : 'fund',
)
const columns = computed(() => {
  return $root.config?.defineColumns ? $root.config?.defineColumns(shareCharacteristics.value) : ['name', 'weight']
})
const detailsDataset = computed(() => {
  let dataset = filteredDataset.value
  return dataset.map(e => ({
    ...e,
    name: e.name || e.fuid,
    weight_fund: e['weight_fund'] || 0,
    price: metric.value === 'fund' ? e.price_fund : e.price_benchmark,
  }))
})
const currentInstrument = computed(() => {
  return detailsDataset.value.find(el => el.fuid === $root.query.fuid)
})

const current = computed(() => {
  const datum = tooltip.value ? assetData.value.find(el => el.date === tooltip.value) : currentInstrument.value
  return [
    [
      ['date', datum.date],
      ['isin', datum.fuid],
    ],
    [['weight', datum['weight_' + metric.value] || 'NA']],
    [['price', datum.price || 'NA']],
    [['contribution', datum['contribution_' + metric.value] || 'NA']],
    [['position', datum['quantity_' + metric.value] || 'NA']],
    [['pnl', datum['pnlOrFeesPerFund_' + metric.value] || 'NA']],
  ]
})
</script>

<script>
export const additions = { calendar: 'period', icon: 'ic_storage' }
</script>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="!loaded")
Error(v-if="error" :textHeader="t['error'] || 'Error'" :textBody="error.message")
template(v-if="loaded && !error")
  .screen-title
    .row.between
      h1 {{ t['details'] || 'details' }}
      autocomplete(:data="autocompleteAvailableData" :options="{ placeholder: t.search }" :modelValue="activeAutocompleteFilters" @update:modelValue="onFiltersUpdateAutoComplete")
  .block
    .row.right.download
      button.primary(@click="exportTable([], extension)")
        span {{ t.export_table }}
      select(@change="changeExt" aria-label="File extension")
        option(v-for="exten in ['csv', 'xlsx']" :value="exten") {{ exten }}
    board(:data="detailsDataset" :metadata="{ expand: 'fuid', sort: '-weight_'+metric, desc: true, columns }")  

      template(v-slot:header-weight)
        div {{ t.weight }}
        span {{ t.on_last_day }}
      template(v-slot:header-contribution)
        div {{ t.contribution }}
        span {{ t.on_period }}
      template(v-slot:header-price_change)
        div {{ t.price_change }}
        span {{ t.on_period }}
      template(v-slot:header-pnl)
        div {{ t.pnl }}
        span {{ t.on_period }}
      template(v-slot:header-quantity)
        div {{ t.quantity }}
        span {{ t.on_last_day }}
      template(v-slot:header-price)
        div {{ t.price }}
        span {{ t.on_last_day }}

      template(v-slot:cell-weight="s")
        div {{ format('weight')(s.line['weight_' + metric + `${metric === 'diff' ? '_benchmark' : ''}`])}}
        span(v-if="metric === 'fund' && $root.tab_benchmark && s.line.weight_benchmark") {{ format('weight_fund')(s.line.weight_benchmark) }}
        span(v-if="metric === 'benchmark' && s.line.weight_fund") {{ format('weight_fund')(s.line.weight_fund) }}
        span(v-if="metric === 'diff'") {{ format('weight_fund')(s.line.weight_fund) }} - {{ format('weight_fund')(s.line.weight_benchmark) }}
      template(v-slot:cell-contribution="s")
        div {{ format('contribution')(s.line['contribution_' + metric + `${metric === 'diff' ? '_benchmark' : ''}`])}}
      template(v-slot:cell-pnl="s")
        div(v-if="metric === 'diff'") {{ format('pnlOrFeesPerFund')(s.line['pnlOrFeesPerFund_fund'] - s.line['pnlOrFeesPerFund_benchmark']) }}
        div(v-else) {{ format('pnlOrFeesPerFund')(s.line['pnlOrFeesPerFund_' + metric]) }}

      template(v-slot:expand="s")
        .plots
          block(v-if="!assetData") loading data ...

          //graph 1
          template(v-else)
            block.weight(
              title='weight'
              type="plot-line"
              :data="formatArrayForPlotline(assetData, [{plot:'fund', field:'weight_fund'}, {plot:'benchmark', field:'weight_benchmark'}])"
              :metadata="{ format: '.2%' }"
              @mounted="$event.xDragbox.destroy()"
              @tooltip="tooltip = $event && $event.date"
              ref="plot_weight"
              v-if="$route.query.mode !== 'euro'"
            )
              template(v-slot:subtitle)
                button.icon.dark.upperCorner(@click="update_query({ mode: 'euro' })") %
            block.position(
              title='position'
              type="plot-line"
              :data="formatArrayForPlotline(assetData, [{plot:'fund', field:'quantity_fund'}])"
              :metadata="{ format: '.3s' }"
              @mounted="$event.xDragbox.destroy()"
              @tooltip="tooltip = $event && $event.date"
              ref="plot_quantity"
              v-else
            )
              template(v-slot:subtitle)
                button.icon.dark.upperCorner(@click="update_query({ mode: null })") €

            //graph 2
            block.price(
              title='price'
              type="plot-line"
              :data="formatArrayForPlotline(assetData, [{plot:'fund', field:'price'}])"
              :metadata="{ format: '.3s' }"
              @mounted="$event.xDragbox.destroy()"
              @tooltip="tooltip = $event && $event.date"
              ref="plot_price"
            )

            //graph 3
            block.contribution(
              title='contribution'
              type="plot-line"
              :data="formatArrayForPlotline(assetData, [{plot:'fund', field:'contribution_fund'}, {plot:'benchmark', field:'contribution_benchmark'}])"
              :metadata="{ variant: 'area', format: 'bp' }"
              @mounted="$event.xDragbox.destroy()"
              @tooltip="tooltip = $event && $event.date"
              ref="plot_contribution"
              v-if="$route.query.mode !== 'euro'"
            )
            block.pnl(
              title='pnl'
              type="plot-line"
              :data="formatArrayForPlotline(assetData, [{plot:'fund', field:'pnlOrFeesPerFund_fund'}, {plot:'benchmark', field:'pnlOrFeesPerFund_benchmark'}])"
              :metadata="{ variant: 'area', format: '.3s€' }"
              @mounted="$event.xDragbox.destroy()"
              @tooltip="tooltip = $event && $event.date"
              ref="plot_pnl"
              v-else
            )

        .block.plots-info.row
          .column(v-for="kpis in current")
            .row(:class="kpi[0]" v-for="kpi in kpis")
              .title {{ t[kpi[0]] || kpi[0] }}
              .result(v-html="new Date(kpi[1]).format('day, mon, year', $root.lang).titleize()" v-if="kpi[0] === 'date'")
              .result(v-else="" v-html="unit(format(kpi[0])(kpi[1]))")
</template>
<style>
/* DETAILS */
.screen-detail.no_benchmark_data .tab {
  display: none;
}

.board .plots-info {
  flex-direction: row;
  align-items: flex-start;
}

.board .plots-info .expandable_kpis {
  column-count: 2;
}

.board .expandable_kpis {
  min-width: 100%;
}

.board .plots-info .expandable_kpis .row {
  min-width: 100%;
}

.board .plots-info .title {
  font-weight: 600;
  color: black;
}

.board .plots-info .result {
  color: black;
}

.board .plots-info .row:nth-child(n + 2) {
  color: black;
}

.board .line:not(.first-line):hover > .tooltip {
  display: none;
}

.board .line:not(.first-line) {
  display: flex !important;
}

button.upperCorner {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  right: 3%;
  padding-left: 9px;
}

button {
  color: white;
  background: var(--primary);
}
</style>
