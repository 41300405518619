<template>
  <builder-block v-bind="props">
    <div class="builder-chart relative flex w-full" :class="[layoutOrientation, `builder-${nxChartOptions?.viz}`]">
      <!-- <div>{{ props.data }}</div> -->
      <nx-chart
        class="w-full"
        :class="nxChartProps?.options?.viz + '-chart'"
        :data="nxChartProps.data"
        :options="nxChartProps.options"
        @parsed="onParsed"
        v-if="nxChartProps.data"
      >
        <template #side>
          <div></div>
        </template>
        <template v-for="(_, name) in $slots" #[name]="slotData">
          <slot :name="name" v-bind="slotData"></slot>
        </template>
      </nx-chart>
      <nx-chart-legend
        v-if="legend"
        :legend="legend"
        :options="nxChartProps.options"
        class="flex flex-row"
        :class="legendClass"
        :style="chartOptions?.legendOptions?.styleFn?.({ data: nxChartProps.data, options: nxChartProps.options })"
      >
        <template #value="{ value }" v-if="chartOptions?.legendOptions?.showValues">
          <span class="legend-value">
            {{ nxChartProps?.options?.formatY ? nxChartProps?.options?.formatY(value) : value }}
          </span>
        </template>
      </nx-chart-legend>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { useBuilderComponent, BuilderChartProps } from '../composables/builderComponent'
import useTranslations from '../composables/translations'

const props = defineProps<BuilderChartProps>()
const { translate } = useTranslations(props)
const parsedProps = reactive({
  data: null,
  options: null,
})
const nxChartProps = useBuilderComponent(props)
function onParsed({ data, options }: any) {
  parsedProps.data = data
  parsedProps.options = options
}
function groupBy(data: any[], key: string) {
  return data.reduce((acc, item) => {
    const k = item[key]
    if (!acc[k]) acc[k] = []
    acc[k].push(item)
    return acc
  }, {})
}
const legend = computed(() => {
  if (!props.data || !props.options.legend || !parsedProps.data) return
  const nxOptions = nxChartProps.options
  if (nxOptions.category && Array.isArray(props.data)) {
    const agg =
      props?.chartOptions?.legendOptions?.showValues === 'evolution'
        ? (data: any[]) =>
            ((data?.[data.length - 1]?.[nxOptions.y] / data?.[0]?.[nxOptions.y] - 1) * 100).toFixed(1) + '%'
        : (data: any[]) => data[data.length - 1]?.[nxOptions.y]
    return (
      Object.entries(groupBy(props.data, nxOptions.category))
        // .sort((a, b) => a[0] - b[0])
        .map(([k, v], i) => {
          const key = props.options?.legendNames?.[k]?.name || k
          // @ts-expect-error palete is never empty TODO: fix INxChartOptions to be partial input and strongly typed output
          return [translate.value(key), agg(v), nxOptions.palette[i]]
        })
    )
  }
  if (nxOptions.viz === 'pie') {
    const agg = (data: any[]) => data.reduce((acc: number, v: any) => acc + v[nxOptions.y], 0)
    // @ts-expect-error palette is never empty TODO: fix INxChartOptions to be partial input and strongly typed output
    return Object.entries(groupBy(parsedProps.data, nxOptions.x)).map(([k, v], i) => {
      const key = props.options?.legendNames?.[k]?.name || k
      return [translate.value(key), agg(v), nxOptions.palette[i]]
    })
  }
  return null
  // return Object.entries(opts.category))
  //   .sort(([k, v]) => k)
  //   .map(([k, v], i) => [k, agg(v), opts.palette[i]])
})

const layoutOrientation = computed(() => {
  const position = props?.chartOptions?.legendOptions?.position
  switch (position) {
    case 'bottom':
      return 'flex-col'
    case 'left':
      return 'flex-row-reverse'
    case 'right':
      return 'flex-row'
    case 'top':
      return 'flex-col-reverse'
    case 'absolute':
      return 'relative'
    default:
      return 'flex-col'
  }
})
const legendClass = computed(() => {
  const position = props?.chartOptions?.legendOptions?.position
  const shape = props?.chartOptions?.legendOptions?.shape
  const orientation = props?.chartOptions?.legendOptions?.orientation
  if (position === 'absolute') return [orientation, shape, 'absolute']
  return [orientation, shape]
})
</script>
<style scoped>
.builder-chart :deep(.nx-chart-legend).vertical {
  @apply flex-col;
}
.builder-chart :deep(.nx-chart-legend).square .legend-color {
  @apply h-3 w-3;
}
.builder-chart :deep(.nx-chart-legend) .legend-item {
  @apply leading-tight;
}
.builder-chart :deep(.nx-chart-legend).circle .legend-color {
  @apply h-3 w-3 rounded-full;
}

.builder-chart :deep(.nx-chart-legend).line .legend-color {
  @apply h-0.5;
}
</style>
